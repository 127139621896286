<template>
  <div class="un-assign-task">
    <v-dialog v-model="isDialogVisible" persistent width="480px">
      <v-card>
        <v-card-title class="amber"><span class="headline">Un-assign Task</span></v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-row class="mt-3">
              <v-col sm="12">
                <v-select
                  :items="unAssignReasons"
                  v-model="unAssignReason"
                  :rules="unAssignReasonRules"
                  required
                  label="Un-assign Reason"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="mx-2">
          <v-spacer></v-spacer>
          <v-btn text @click="onCancel()">Cancel</v-btn>
          <v-btn text color="warning" :disabled="!valid" @click="onUnAssign(task)">Un-assign</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'Un-Assign-Task',
  props: {
    unAssignTaskDialog: {
      type: Boolean,
      default: false,
    },
    task: {
      type: Object,
      require: false,
    },
    caseObject: {
      type: Object,
      require: false,
    },
  },
  data() {
    return {
      valid: false,
      unAssignReason: '',
      unAssignReasonRules: [(v) => !!v || 'Reason is required'],
      unAssignReasons: ['End of shift', 'Not trained on process step', 'Case not available in TDA'],
    };
  },
  computed: {
    ...mapGetters('cases', { cases: 'getCases' }),
    ...mapGetters('accounts', { currentAccount: 'getCurrentAccount' }),
    isDialogVisible: {
      get() {
        return this.unAssignTaskDialog;
      },
      set(value) {
        this.$emit('dialog-state-change', value);
      },
    },
  },
  methods: {
    ...mapActions('cases', { updateCase: 'updateCase' }),
    onCancel() {
      this.isDialogVisible = false;
    },
    async onUnAssign(item) {
      item['unAssigned'] = [
        {
          caseStatus: item.caseStatus,
          email: item.currentlyAssignedRep,
          reason: this.unAssignReason,
          timeStamp: this.$moment
            .utc()
            .subtract(4, 'hours')
            .format('MM-DD-YYYY HH:mm:ss'),
        },
      ];
      item['updatedBy'] = this.$store.state.accounts.account.username;
      item['updatedDate'] = this.$moment().utc();
      if (item.caseStatus === 'Assigned - Data Review') {
        item['caseStatus'] = 'Awaiting Data Review';
        item['currentlyAssignedRep'] = '';
      }
      if (item.caseStatus === 'Assigned - Initial Segmentation') {
        item['caseStatus'] = 'Awaiting Initial Segmentation';
        item['currentlyAssignedRep'] = '';
      }
      if (item.caseStatus === 'Assigned - Segmentation Review') {
        item['caseStatus'] = 'Awaiting Segmentation Review';
        item['currentlyAssignedRep'] = '';
      }
      if (item.caseStatus === 'Assigned - Plan Review & Upload') {
        item['caseStatus'] = 'Awaiting Plan Review & Upload';
        item['currentlyAssignedRep'] = '';
      }
      await this.updateCase(item);
      this.$emit('dialog-state-change');
    },
  },

  watch: {},
};
</script>

<style>
.border {
  border: 1px solid black;
}
.border-top {
  border-top: 1px solid black;
}
.border-right {
  border-right: 1px solid black;
}
</style>
