<template>
  <div class="tasks">
    <v-row>
      <v-col sm="12">
        <span class="headline">{{ pageName }}</span>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col sm="4">
        <v-menu
          ref="needDateMenu"
          v-model="needDateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              readonly
              clearable
              label="Select a Need By Date range"
              append-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="needDate" range no-title></v-date-picker>
        </v-menu>
      </v-col>
      <v-col sm="4">
        <v-select :items="segRegions" v-model="segRegion" multiple clearable label="Select a Seg Region">
          <template v-slot:selection="{ item, index }">
            <v-chip x-small v-if="index === 0">
              <span>{{ item }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text body-1"> (+{{ segRegion.length - 1 }} more) </span>
          </template>
        </v-select>
      </v-col>
      <v-col sm="4">
        <v-select :items="mpsRegions" v-model="mpsRegion" multiple clearable label="Select an MPS Region">
          <template v-slot:selection="{ item, index }">
            <v-chip x-small v-if="index === 0">
              <span>{{ item }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text body-1"> (+{{ mpsRegion.length - 1 }} more) </span>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-container class="mt-5">
      <v-row class="text-center elevation-3 border">
        <v-col sm="12">
          <v-row class="amber">
            <v-col sm="2" class="border-right"> </v-col>
            <v-col sm="2" class="border-right">
              <span class="subtitle-2">Data Review</span>
            </v-col>
            <v-col sm="2" class="border-right">
              <span class="subtitle-2">Initial Seg</span>
            </v-col>
            <v-col sm="2" class="border-right">
              <span class="subtitle-2">Seg Review</span>
            </v-col>
            <v-col sm="2" class="border-right">
              <span class="subtitle-2">Plan & Upload</span>
            </v-col>
            <v-col sm="2">
              <span class="subtitle-2">Total</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col sm="12" class="border-top">
          <v-row class="amber">
            <v-col sm="2" class="border-right">
              <span class="subtitle-2">Case Type</span>
            </v-col>
            <v-col sm="1" class="">
              <span class="subtitle-2">TDA</span>
            </v-col>
            <v-col sm="1" class="border-right">
              <span class="subtitle-2">Legacy</span>
            </v-col>
            <v-col sm="1" class="">
              <span class="subtitle-2">TDA</span>
            </v-col>
            <v-col sm="1" class="border-right">
              <span class="subtitle-2">Legacy</span>
            </v-col>
            <v-col sm="1" class="">
              <span class="subtitle-2">TDA</span>
            </v-col>
            <v-col sm="1" class="border-right">
              <span class="subtitle-2">Legacy</span>
            </v-col>
            <v-col sm="1" class="">
              <span class="subtitle-2">TDA</span>
            </v-col>
            <v-col sm="1" class="border-right">
              <span class="subtitle-2">Legacy</span>
            </v-col>
            <v-col sm="1" class="">
              <span class="subtitle-2">TDA</span>
            </v-col>
            <v-col sm="1">
              <span class="subtitle-2">Legacy</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col sm="12" class="border-top">
          <v-row>
            <v-col sm="2" class="border-right">
              <span class="subtitle-2">TKA</span>
            </v-col>
            <v-col sm="1" class="">
              <span class="body-1">{{ TKADataReviewTDA.length }}</span>
            </v-col>
            <v-col sm="1" class="border-right">
              <span class="body-1">{{ TKADataReviewLegacy.length }}</span>
            </v-col>
            <v-col sm="1" class="">
              <span class="body-1">{{ TKAInitialSegTDA.length }}</span>
            </v-col>
            <v-col sm="1" class="border-right">
              <span class="body-1">{{ TKAInitialSegLegacy.length }}</span>
            </v-col>
            <v-col sm="1" class="">
              <span class="body-1">{{ TKASegReviewTDA.length }}</span>
            </v-col>
            <v-col sm="1" class="border-right">
              <span class="body-1">{{ TKASegReviewLegacy.length }}</span>
            </v-col>
            <v-col sm="1" class="">
              <span class="body-1">{{ TKAPlanUploadTDA.length }}</span>
            </v-col>
            <v-col sm="1" class="border-right">
              <span class="body-1">{{ TKAPlanUploadLegacy.length }}</span>
            </v-col>
            <v-col sm="1" class="">
              <span class="body-1">{{ TotalTKATDA.length }}</span>
            </v-col>
            <v-col sm="1">
              <span class="body-1">{{ TotalTKALegacy.length }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col sm="12" class="border-top">
          <v-row class="grey lighten-4">
            <v-col sm="2" class="border-right">
              <span class="subtitle-2">THA</span>
            </v-col>
            <v-col sm="1" class="">
              <span class="body-1">{{ THADataReviewTDA.length }}</span>
            </v-col>
            <v-col sm="1" class="border-right">
              <span class="body-1">{{ THADataReviewLegacy.length }}</span>
            </v-col>
            <v-col sm="1" class="">
              <span class="body-1">{{ THAInitialSegTDA.length }}</span>
            </v-col>
            <v-col sm="1" class="border-right">
              <span class="body-1">{{ THAInitialSegLegacy.length }}</span>
            </v-col>
            <v-col sm="1" class="">
              <span class="body-1">{{ THASegReviewTDA.length }}</span>
            </v-col>
            <v-col sm="1" class="border-right">
              <span class="body-1">{{ THASegReviewLegacy.length }}</span>
            </v-col>
            <v-col sm="1" class="">
              <span class="body-1">{{ THAPlanUploadTDA.length }}</span>
            </v-col>
            <v-col sm="1" class="border-right">
              <span class="body-1">{{ THAPlanUploadLegacy.length }}</span>
            </v-col>
            <v-col sm="1" class="">
              <span class="body-1">{{ TotalTHATDA.length }}</span>
            </v-col>
            <v-col sm="1">
              <span class="body-1">{{ TotalTHALegacy.length }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col sm="12" class="border-top">
          <v-row>
            <v-col sm="2" class="border-right">
              <span class="subtitle-2">Cadaver</span>
            </v-col>
            <v-col sm="1" class="">
              <span class="body-1">{{ CadaverDataReviewTDA.length }}</span>
            </v-col>
            <v-col sm="1" class="border-right">
              <span class="body-1">{{ CadaverDataReviewLegacy.length }}</span>
            </v-col>
            <v-col sm="1" class="">
              <span class="body-1">{{ CadaverInitialSegTDA.length }}</span>
            </v-col>
            <v-col sm="1" class="border-right">
              <span class="body-1">{{ CadaverInitialSegLegacy.length }}</span>
            </v-col>
            <v-col sm="1" class="">
              <span class="body-1">{{ CadaverSegReviewTDA.length }}</span>
            </v-col>
            <v-col sm="1" class="border-right">
              <span class="body-1">{{ CadaverSegReviewLegacy.length }}</span>
            </v-col>
            <v-col sm="1" class="">
              <span class="body-1">{{ CadaverPlanUploadTDA.length }}</span>
            </v-col>
            <v-col sm="1" class="border-right">
              <span class="body-1">{{ CadaverPlanUploadLegacy.length }}</span>
            </v-col>
            <v-col sm="1" class="">
              <span class="body-1">{{ TotalCadaverTDA.length }}</span>
            </v-col>
            <v-col sm="1">
              <span class="body-1">{{ TotalCadaverLegacy.length }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col sm="12" class="border-top">
          <v-row class="grey lighten-4">
            <v-col sm="2" class="border-right">
              <span class="subtitle-2">Total</span>
            </v-col>
            <v-col sm="1" class="">
              <span class="body-1">{{ TotalDataReviewTDA.length }}</span>
            </v-col>
            <v-col sm="1" class="border-right">
              <span class="body-1">{{ TotalDataReviewLegacy.length }}</span>
            </v-col>
            <v-col sm="1" class="">
              <span class="body-1">{{ TotalInitialSegTDA.length }}</span>
            </v-col>
            <v-col sm="1" class="border-right">
              <span class="body-1">{{ TotalInitialSegLegacy.length }}</span>
            </v-col>
            <v-col sm="1" class="">
              <span class="body-1">{{ TotalSegReviewTDA.length }}</span>
            </v-col>
            <v-col sm="1" class="border-right">
              <span class="body-1">{{ TotalSegReviewLegacy.length }}</span>
            </v-col>
            <v-col sm="1" class="">
              <span class="body-1">{{ TotalPlanUploadTDA.length }}</span>
            </v-col>
            <v-col sm="1" class="border-right">
              <span class="body-1">{{ TotalPlanUploadLegacy.length }}</span>
            </v-col>
            <v-col sm="1" class="">
              <span class="body-1">{{ TotalTDA.length }}</span>
            </v-col>
            <v-col sm="1">
              <span class="body-1">{{ TotalLegacy.length }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-row v-if="!task">
      <v-col sm="12" class="mt-10">
        <Task :cases="cases" @force-re-render="forceRerender" :key="taskKey" />
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col sm="12" class="mt-10">
        <v-container class="mt-3">
          <v-row class="text-center elevation-3 border">
            <v-col class="sm-12">
              <v-row class="grey lighten-1">
                <v-col sm="2" class="border-right">
                  <span class="subtitle-2">Unique Identifier</span>
                </v-col>
                <v-col sm="3" class="border-right" v-if="task.caseType == 'TKA'">
                  <span class="subtitle-2">Case Status</span>
                </v-col>
                <v-col sm="3" class="border-right" v-if="task.caseType == 'THA' || task.caseType == 'Cadaver'">
                  <span class="subtitle-2">Case Status</span>
                </v-col>
                <v-col sm="2" class="border-right">
                  <span class="subtitle-2">MPS Region</span>
                </v-col>
                <v-col sm="2" class="border-right">
                  <span class="subtitle-2">Seg Region</span>
                </v-col>
                <v-col sm="1" class="border-right">
                  <span class="subtitle-2">TDA</span>
                </v-col>
                <v-col sm="1" class="border-right" v-if="task.caseType == 'THA' || task.caseType == 'Cadaver'">
                  <span class="subtitle-2">THA Ver.</span>
                </v-col>
                <v-col sm="1" class="border-right" v-if="task.caseType == 'TKA'">
                  <span class="subtitle-2">TKA Ver.</span>
                </v-col>
                <v-col sm="1">
                  <span class="subtitle-2">Actions</span>
                </v-col>
              </v-row>
              <v-row class="border-top">
                <v-col sm="2" class="border-right">
                  <span class="subtitle-2">{{ task.UniqueIdentifier }}</span>
                </v-col>
                <v-col sm="3" class="border-right" v-if="task.caseType == 'TKA'">
                  <span class="subtitle-2">{{ task.caseStatus }}</span>
                </v-col>
                <v-col sm="3" class="border-right" v-if="task.caseType == 'THA' || task.caseType == 'Cadaver'">
                  <span class="subtitle-2">{{ task.caseStatus }}</span>
                </v-col>
                <v-col sm="2" class="border-right">
                  <span class="subtitle-2">{{ task.coveringRepRegion }}</span>
                </v-col>
                <v-col sm="2" class="border-right">
                  <span class="subtitle-2" v-if="task.segRegion">{{ task.segRegion }}</span>
                  <span class="subtitle-2" v-else>Empty</span>
                </v-col>
                <v-col sm="1" class="border-right">
                  <span class="subtitle-2" v-if="task.isTDA">YES</span>
                  <span class="subtitle-2" v-if="!task.isTDA">NO</span>
                </v-col>
                <v-col sm="1" class="border-right" v-if="task.caseType == 'THA' || task.caseType == 'Cadaver'">
                  <span class="subtitle-2">{{ task.thaVersion }}</span>
                </v-col>
                <v-col sm="1" class="border-right" v-if="task.caseType == 'TKA'">
                  <span class="subtitle-2">{{ task.tkaVersion }}</span>
                </v-col>
                <v-col sm="1">
                  <v-menu top left tile>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item-group>
                        <!-- :to="{ name: 'Case View', params: { id: item.Id } }" -->
                        <v-list-item @click="$router.push({ name: 'Case', params: { id: task.id } })">
                          <v-list-item-icon>
                            <v-icon>mdi-pencil</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Edit</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item @click="onUnAssign">
                          <v-list-item-icon>
                            <v-icon>mdi-close-circle</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Un-assign</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <UnAssignTask
      :task="task"
      :unAssignTaskDialog="unAssignTaskDialog"
      @dialog-state-change="onDialogStateChange"
      :key="unAssignTaskKey"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Task from '@/components/Task.vue';
import UnAssignTask from '@/components/UnAssignTask.vue';
export default {
  name: 'surgeons',
  components: {
    Task,
    UnAssignTask,
  },
  data() {
    return {
      pageName: 'Tasks',
      taskKey: 0,
      task: null,
      unAssignTaskKey: 100,
      nowDate: new Date().toISOString().slice(0, 10),
      needDateMenu: false,
      needDate: [],
      segRegion: '',
      segRegions: ['US - Fort Lauderdale, FL', 'US - Kalamazoo, MI', 'APAC', 'SSP'],
      mpsRegion: '',
      mpsRegions: ['US', 'APAC', 'SSP'],
      unAssignTaskDialog: false,
    };
  },
  computed: {
    ...mapGetters('cases', { cases: 'getCases' }),
    ...mapGetters('accounts', { currentAccount: 'getCurrentAccount' }),
    allCases() {
      if (this.needDate.length) {
        return this.cases.filter((item) => this.dateRange.includes(item.needDate));
      }
      if (this.segRegion.length) {
        return this.cases.filter((item) => this.segRegion.includes(item.segRegion));
      }
      if (this.mpsRegion.length) {
        return this.cases.filter((item) => this.mpsRegion.includes(item.coveringRepRegion));
      }
      return this.cases;
    },
    pendingTask() {
      return this.cases.find(
        (item) => item.currentlyAssignedRep === this.currentAccount.email && item.caseStatus.includes('Pending')
      );
    },
    dateRange() {
      const days = [];
      const startDate = this.$moment(this.needDate[0]);
      const endDate = this.$moment(this.needDate[1]);
      while (endDate.diff(startDate, 'days') >= 0) {
        days.push(startDate.format('YYYY-MM-DD'));
        startDate.add(1, 'days');
      }
      return days;
    },
    dateRangeText: {
      get() {
        return this.needDate.join(' ~ ');
      },
      set() {
        return (this.needDate = []);
      },
    },
    // Awaiting Data Review
    TKADataReviewTDA() {
      return this.tableFilter('TKA', 'Awaiting Data Review', true);
    },
    TKADataReviewLegacy() {
      return this.tableFilter('TKA', 'Awaiting Data Review', false);
    },
    THADataReviewTDA() {
      return this.tableFilter('THA', 'Awaiting Data Review', true);
    },
    THADataReviewLegacy() {
      return this.tableFilter('THA', 'Awaiting Data Review', false);
    },
    CadaverDataReviewTDA() {
      return this.tableFilter('Cadaver', 'Awaiting Data Review', true);
    },
    CadaverDataReviewLegacy() {
      return this.tableFilter('Cadaver', 'Awaiting Data Review', false);
    },
    TotalDataReviewTDA() {
      return this.TKADataReviewTDA.concat(this.THADataReviewTDA, this.CadaverDataReviewTDA);
    },
    TotalDataReviewLegacy() {
      return this.TKADataReviewLegacy.concat(this.THADataReviewLegacy, this.CadaverDataReviewLegacy);
    },
    // Awaiting Initial Segmentation
    TKAInitialSegTDA() {
      return this.tableFilter('TKA', 'Awaiting Initial Segmentation', true);
    },
    TKAInitialSegLegacy() {
      return this.tableFilter('TKA', 'Awaiting Initial Segmentation', false);
    },
    THAInitialSegTDA() {
      return this.tableFilter('THA', 'Awaiting Initial Segmentation', true);
    },
    THAInitialSegLegacy() {
      return this.tableFilter('THA', 'Awaiting Initial Segmentation', false);
    },
    CadaverInitialSegTDA() {
      return this.tableFilter('Cadaver', 'Awaiting Initial Segmentation', true);
    },
    CadaverInitialSegLegacy() {
      return this.tableFilter('Cadaver', 'Awaiting Initial Segmentation', false);
    },
    TotalInitialSegTDA() {
      return this.TKAInitialSegTDA.concat(this.THAInitialSegTDA, this.CadaverInitialSegTDA);
    },
    TotalInitialSegLegacy() {
      return this.TKAInitialSegLegacy.concat(this.THAInitialSegLegacy, this.CadaverInitialSegLegacy);
    },
    // Awaiting Segmentation Review
    TKASegReviewTDA() {
      return this.tableFilter('TKA', 'Awaiting Segmentation Review', true);
    },
    TKASegReviewLegacy() {
      return this.tableFilter('TKA', 'Awaiting Segmentation Review', false);
    },
    THASegReviewTDA() {
      return this.tableFilter('THA', 'Awaiting Segmentation Review', true);
    },
    THASegReviewLegacy() {
      return this.tableFilter('THA', 'Awaiting Segmentation Review', false);
    },
    CadaverSegReviewTDA() {
      return this.tableFilter('Cadaver', 'Awaiting Segmentation Review', true);
    },
    CadaverSegReviewLegacy() {
      return this.tableFilter('Cadaver', 'Awaiting Segmentation Review', false);
    },
    TotalSegReviewTDA() {
      return this.TKASegReviewTDA.concat(this.THASegReviewTDA, this.CadaverSegReviewTDA);
    },
    TotalSegReviewLegacy() {
      return this.TKASegReviewLegacy.concat(this.THASegReviewLegacy, this.CadaverSegReviewLegacy);
    },
    // Awaiting Plan Review & Upload
    TKAPlanUploadTDA() {
      return this.tableFilter('TKA', 'Awaiting Plan Review & Upload', true);
    },
    TKAPlanUploadLegacy() {
      return this.tableFilter('TKA', 'Awaiting Plan Review & Upload', false);
    },
    THAPlanUploadTDA() {
      return this.tableFilter('THA', 'Awaiting Plan Review & Upload', true);
    },
    THAPlanUploadLegacy() {
      return this.tableFilter('THA', 'Awaiting Plan Review & Upload', false);
    },
    CadaverPlanUploadTDA() {
      return this.tableFilter('Cadaver', 'Awaiting Plan Review & Upload', true);
    },
    CadaverPlanUploadLegacy() {
      return this.tableFilter('Cadaver', 'Awaiting Plan Review & Upload', false);
    },
    TotalPlanUploadTDA() {
      return this.TKAPlanUploadTDA.concat(this.THAPlanUploadTDA, this.CadaverPlanUploadTDA);
    },
    TotalPlanUploadLegacy() {
      return this.TKAPlanUploadLegacy.concat(this.THAPlanUploadLegacy, this.CadaverPlanUploadLegacy);
    },
    // TDA and Legacy Totals
    TotalTKATDA() {
      return this.TKADataReviewTDA.concat(this.TKAInitialSegTDA, this.TKASegReviewTDA, this.TKAPlanUploadTDA);
    },
    TotalTKALegacy() {
      return this.TKADataReviewLegacy.concat(
        this.TKAInitialSegLegacy,
        this.TKASegReviewLegacy,
        this.TKAPlanUploadLegacy
      );
    },
    TotalTHATDA() {
      return this.THADataReviewTDA.concat(this.THAInitialSegTDA, this.THASegReviewTDA, this.THAPlanUploadTDA);
    },
    TotalTHALegacy() {
      return this.THADataReviewLegacy.concat(
        this.THAInitialSegLegacy,
        this.THASegReviewLegacy,
        this.THAPlanUploadLegacy
      );
    },
    TotalCadaverTDA() {
      return this.CadaverDataReviewTDA.concat(
        this.CadaverInitialSegTDA,
        this.CadaverSegReviewTDA,
        this.CadaverPlanUploadTDA
      );
    },
    TotalCadaverLegacy() {
      return this.CadaverDataReviewLegacy.concat(
        this.CadaverInitialSegLegacy,
        this.CadaverSegReviewLegacy,
        this.CadaverPlanUploadLegacy
      );
    },
    TotalTDA() {
      return this.TotalTKATDA.concat(this.TotalTHATDA, this.TotalCadaverTDA);
    },
    TotalLegacy() {
      return this.TotalTKALegacy.concat(this.TotalTHALegacy, this.TotalCadaverLegacy);
    },
  },
  created() {
    this.$store.dispatch('cases/getCases');
    this.setTask();
  },

  methods: {
    ...mapActions('cases', { updateCase: 'updateCase' }),
    async forceRerender() {
      this.taskKey += 1;
      this.unAssignTaskKey += 1;
      this.setTask();
      this.$store.dispatch('cases/getCases');
    },
    tableFilter(caseType, caseStatus, isTDA) {
      return this.allCases.filter(
        (item) => item.caseType === caseType && item.caseStatus === caseStatus && item.isTDA === isTDA
      );
    },
    onDialogStateChange(state) {
      this.unAssignTaskDialog = state;
    },
    onUnAssign() {
      this.unAssignTaskDialog = true;
    },
    async setTask() {
      let payload = {
        currentlyAssignedRep: this.currentAccount.email,
      };
      this.task = await this.$http.post(`${this.$store.state.apiURL}/getTasks`, payload).then((res) => {
        return res.data.items[0];
      });
      console.log(this.task);
    },
  },
  watch: {
    taskKey: function(newValue) {
      if (!newValue) {
        console.log('New value: ', { newValue: newValue });
        this.forceRerender();
      }
    },
    unAssignTaskDialog: function(newValue) {
      if (!newValue) {
        console.log('New value: ', { newValue: newValue });
        this.forceRerender();
      }
    },
    dateRange: function(newValue) {
      if (newValue) {
        console.log('Date Range: ', { newValue: newValue });
      }
    },
    dateRangeText: function(newValue) {
      if (newValue) {
        console.log('Date Range Text: ', { newValue: newValue });
      }
    },
    // task: function(newValue) {
    //   if (newValue && newValue.caseStatus.includes('Pending')) {
    //     setTimeout(() => {
    //       this.task['caseStatus'] = newValue.caseStatus.replace('Pending', 'Assigned');
    //       this.updateCase(this.task);
    //     }, 15000);
    //   }
    // },
  },
};
</script>
<style>
.border {
  border: 1px solid black;
}
.border-top {
  border-top: 1px solid black;
}
.border-right {
  border-right: 1px solid black;
}
</style>
