<template>
  <div class="task">
    <v-form ref="form" v-model="valid">
      <v-row>
        <!-- <v-col sm="2">
          <v-select :items="caseTypes" v-model="caseType" :rules="caseTypeRules" required label="Case Type"></v-select>
        </v-col> -->
        <v-col sm="2">
          <v-switch v-model="isTDA" label="TDA"></v-switch>
        </v-col>
        <!-- <v-col sm="2">
          <v-switch v-model="isTHA4" :disabled="isTKA" label="THA 4.0"></v-switch>
        </v-col> -->
        <v-col sm="4">
          <v-select
            :items="caseStatuses"
            v-model="caseStatus"
            :rules="caseStatusRules"
            required
            label="Case Status"
          ></v-select>
        </v-col>
        <v-col sm="2">
          <v-select :items="mpsRegions" v-model="mpsRegion" :rules="mpsRegionRules" required label="Region"></v-select>
        </v-col>
        <v-col sm="4" offset="0" class="text-right">
          <v-btn class="ma-2" outlined elevation="2" @click="onReset">Reset</v-btn>
          <v-btn elevation="2" outlined color="warning" :disabled="!valid" @click="onAssign">Assign</v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-dialog v-model="loading" persistent fullscreen>
      <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.5);">
        <v-layout row justify-center align-center>
          <v-progress-circular indeterminate :size="70" :width="7" color="amber"></v-progress-circular>
        </v-layout>
      </v-container>
    </v-dialog>
    <v-dialog v-model="taskDialog" persistent width="640px">
      <v-card>
        <v-card-title class="amber"><span class="headline">Task offer</span></v-card-title>
        <v-card-text>
          <v-row class="mt-3">
            <v-col class="sm-12" v-if="!this.task">
              <p>There are no cases much your criteria. Please update your selections and try again.</p>
            </v-col>
            <v-col class="sm-12" v-if="this.task">
              <v-container class="mt-3">
                <v-row class="text-center elevation-3 border">
                  <v-col class="sm-12">
                    <v-row class=" amber">
                      <v-col sm="3" class="border-right">
                        <span class="subtitle-2">Unique Identifier</span>
                      </v-col>
                      <v-col sm="3" class="border-right">
                        <span class="subtitle-2">Urgent Case</span>
                      </v-col>
                      <v-col sm="3" class="border-right">
                        <span class="subtitle-2">Share Date</span>
                      </v-col>
                      <v-col sm="3">
                        <span class="subtitle-2">Need by Date</span>
                      </v-col>
                    </v-row>
                    <v-row class="border-top">
                      <v-col sm="3" class="border-right">
                        <span class="subtitle-2">{{ task.UniqueIdentifier }}</span>
                      </v-col>
                      <v-col sm="3" class="border-right">
                        <span class="subtitle-2" v-if="task.isUrgent">YES</span>
                        <span class="subtitle-2" v-if="!task.isUrgent">NO</span>
                      </v-col>
                      <v-col sm="3" class="border-right">
                        <span class="subtitle-2">{{ task.shareDate | formatDate }}</span>
                      </v-col>
                      <v-col sm="3">
                        <span class="subtitle-2">{{ task.needDate | formatDate }}</span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
              <v-col class="sm-12 mt-3">
                <p>Select ACCEPT to accept this task offer or REJECT to return back to tasks dashboard.</p>
              </v-col>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="mx-2">
          <v-progress-circular
            v-if="this.tasks.length"
            :rotate="270"
            :size="32"
            :width="4"
            :value="timer"
            color="amber"
          >
            {{ countDown }}
          </v-progress-circular>
          <v-spacer></v-spacer>
          <div v-if="!this.tasks.length">
            <v-btn text @click="onCancel">Cancel</v-btn>
          </div>
          <div v-if="this.tasks.length">
            <v-btn text @click="onReject(task)">Cancel</v-btn>
            <v-btn text color="warning" :disabled="!valid" @click="onAccept(task)">Accept</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'task',
  data() {
    return {
      valid: false,
      search: null,
      caseType: '',
      caseTypeRules: [(v) => !!v || 'Case type is required'],
      caseTypes: ['TKA', 'THA', 'Cadaver'],
      isTDA: true,
      isTHA4: false,
      caseStatus: '',
      caseStatusRules: [(v) => !!v || 'Case status is required'],
      mpsRegion: 'Any',
      mpsRegionRules: [(v) => !!v || 'MPS region is required'],
      mpsRegions: ['Any', 'US', 'APAC', 'SSP'],
      taskDialog: false,
      tasks: [],
      task: null,
      countDown: 15,
      caseStatuses: ['Data Review', 'Initial Segmentation', 'Segmentation Review', 'Plan Review & Upload'],
    };
  },
  computed: {
    ...mapGetters('cases', { cases: 'getCases' }),
    ...mapGetters('accounts', { currentAccount: 'getCurrentAccount' }),
    ...mapGetters('settings', { delayValue: 'getDelayValue' }),
    // caseStatuses() {
    //   if (this.caseType === 'TKA' && this.isTDA === false) {
    //     return ['Data Review', 'Plan Review & Upload'];
    //   } else {
    //     return ['Data Review', 'Initial Segmentation', 'Segmentation Review', 'Plan Review & Upload'];
    //   }
    // },
    isTKA() {
      if (this.caseType === 'TKA' || !this.caseType) {
        return true;
      } else {
        return false;
      }
    },
    timer() {
      return this.countDown * 6.66;
    },
    timeNow() {
      return Math.round(new Date().getTime() / 1000);
    },
    loading: {
      get() {
        return this.$store.state.loading;
      },
      set(value) {
        this.$store.dispatch('setLoading', value);
      },
    },
  },
  methods: {
    ...mapActions('cases', { updateCase: 'updateCase' }),
    ...mapActions('settings', { getSettings: 'getSettings' }),
    onReset() {
      this.$emit('force-re-render');
    },
    async onAssign() {
      this.loading = true;
      let payload = {
        caseType: this.caseType,
        isTDA: this.isTDA,
        isTHA4: this.isTHA4,
        caseStatus: `Awaiting ${this.caseStatus}`,
        mpsRegion: this.mpsRegion,
        delayValue: this.delayValue,
        repEmail: this.currentAccount.email,
        repRegion: this.currentAccount.region,
      };
      let request = await this.$http.post(`${this.$store.state.apiURL}/requestTask`, payload).then((res) => {
        return res;
      });

      console.log('Request: ', request);
    },

    async onReject(item) {
      if (item.caseStatus === 'Pending - Data Review') {
        item['caseStatus'] = 'Awaiting Data Review';
        item['currentlyAssignedRep'] = '';
      }
      if (item.caseStatus === 'Pending - Initial Segmentation') {
        item['caseStatus'] = 'Awaiting Initial Segmentation';
        item['currentlyAssignedRep'] = '';
      }
      if (item.caseStatus === 'Pending - Segmentation Review') {
        item['caseStatus'] = 'Awaiting Segmentation Review';
        item['currentlyAssignedRep'] = '';
      }
      if (item.caseStatus === 'Pending - Plan Review & Upload') {
        item['caseStatus'] = 'Awaiting Plan Review & Upload';
        item['currentlyAssignedRep'] = '';
      }
      item['updatedBy'] = this.$store.state.accounts.account.username;
      item['updatedDate'] = this.$moment().utc();
      await this.updateCase(item);
      this.$emit('force-re-render');
    },
    async onAccept(item) {
      if (item.caseStatus === 'Pending - Data Review') {
        item['caseStatus'] = 'Assigned - Data Review';
        item['currentlyAssignedRep'] = this.currentAccount.email;
      }
      if (item.caseStatus === 'Pending - Initial Segmentation') {
        item['caseStatus'] = 'Assigned - Initial Segmentation';
        item['currentlyAssignedRep'] = this.currentAccount.email;
      }
      if (item.caseStatus === 'Pending - Segmentation Review') {
        item['caseStatus'] = 'Assigned - Segmentation Review';
        item['currentlyAssignedRep'] = this.currentAccount.email;
      }
      if (item.caseStatus === 'Pending - Plan Review & Upload') {
        item['caseStatus'] = 'Assigned - Plan Review & Upload';
        item['currentlyAssignedRep'] = this.currentAccount.email;
      }
      item['updatedBy'] = this.$store.state.accounts.account.username;
      item['updatedDate'] = this.$moment().utc();
      await this.updateCase(item);
      this.$emit('force-re-render');
    },
    onCancel() {
      this.$emit('force-re-render');
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
  },
  created() {
    this.getSettings();
  },

  watch: {
    caseType: function(newValue) {
      if (!newValue) {
        return;
      }
      if (newValue == 'TKA') {
        this.isTHA4 = false;
      }
      if ((newValue && newValue == 'THA') || newValue == 'Cadaver') {
        this.isTHA4 = false;
      }
    },
    countDown: function(newValue) {
      if (!newValue) {
        return;
      }
      if (newValue <= 1) {
        return this.onReject(this.task);
      }
    },
    loading: function(newValue) {
      console.log('newValue', newValue);
      if (newValue === false) {
        console.log('loading false');
        this.$emit('force-re-render');
      }
    },
  },
};
</script>

<style>
.border {
  border: 1px solid black;
}
.border-top {
  border-top: 1px solid black;
}
.border-right {
  border-right: 1px solid black;
}
</style>
